require('../../../corporate-template/js/feature/form.js');
(function ($, DGS) {
  DGS.OnLoad.getInstance().register(function () {
    adjustLabelWhenFieldRequired();
    function adjustLabelWhenFieldRequired() {
      var formFields = $('.scfSectionContent > div');
      formFields.each(function () {
        var self = $(this);
        var required = self.find('.scfRequired');
        var validatorRequired = self.find('.scfValidatorRequired ');
        var label = self.find('label')[0];
        if (label) {
          var labelText = label.innerHTML;
          if (required.length > 0 || validatorRequired.length > 0) {
            label.innerHTML = '<span class="field-required">*</span>' + labelText;
            required.hide();
            validatorRequired.hide();
          }
        }
      });
    }
    adjustNiceSelect();
    function adjustNiceSelect() {
      var niceSelectList = $('.scfSectionContent .nice-select .list ul');
      niceSelectList.each(function () {
        var self = $(this);
        var options = self.find('li.option');
        options.each(function () {
          var option = $(this);
          hideEmptyOptionInNiceSelect(option);
        });
      });
    }
    function hideEmptyOptionInNiceSelect(el) {
      if (el.attr('data-value') == '') {
        el.hide();
      }
    }
    customizeCheckbox();
    function customizeCheckbox() {
      var labels = $('.scfSectionContent .scfCheckbox label, .scfSectionContent .scfCheckBoxList label');
      customizeRadioOrCheckbox(labels);
    }
    customizeRadioBtn();
    function customizeRadioBtn() {
      var labels = $('.scfSectionContent .scfRadioButtonList tr td label');
      customizeRadioOrCheckbox(labels);
    }
    function customizeRadioOrCheckbox(container) {
      container.each(function () {
        var self = $(this);
        var input = self.siblings('input');
        var customCheckmarkHTML = '<span class="checkmark"></span>';
        self.append(input).append(customCheckmarkHTML);
      });
    }
    function niceSelectScroll() {
      var listContainer = $('.form .nice-select');
      listContainer.each(function () {
        $(this).on('focus', function () {
          var list = $(this).find('.list')[0];
          var firstItemEmpty = $(this).find('.option[data-value=""]:first-of-type').length > 0;
          var counter = 0;
          document.onkeydown = function (e) {
            var listItemHeight = $(this).find('.option:not([data-value=""])').outerHeight(true);
            var listIsAtTop = list.scrollTop === 0;
            if (e.keyCode === 40) {
              if (firstItemEmpty && listIsAtTop && counter === 0) {
                counter++;
              } else {
                list.scrollTop += listItemHeight;
              }
            }
            if (e.keyCode === 38) {
              if (listIsAtTop) {
                counter = 0;
              }
              list.scrollTop -= listItemHeight;
            }
          };
        });
      });
    }
    niceSelectScroll();
  });
})(jQuery, window.DGS);