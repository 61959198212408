(function ($, DGS) {
  var socialSharingConfig = {
    layout: "float",
    insertAfter: "#content",
    serviceElementsHeaderClass: "service-elements-header",
    serviceElementClass: "service-element",
    shareWindowDimensions: "width=626,height=436"
  };
  DGS.OnLoad.getInstance().register(function () {
    if ($("#social-sharing").length) {
      $("#social-sharing").sharingTools({
        layout: socialSharingConfig.layout,
        insertAfter: socialSharingConfig.insertAfter,
        serviceElementsHeaderClass: socialSharingConfig.serviceElementsHeaderClass,
        serviceElementClass: socialSharingConfig.serviceElementClass,
        shareWindowDimensions: socialSharingConfig.shareWindowDimensions
      });
    }
    makeSpaceForSocialSharing();
  });
  DGS.Resizer.getInstance().register(function () {
    makeSpaceForSocialSharing();
  });
  function makeSpaceForSocialSharing() {
    var socialSharingHeight = '3.5714rem';
    if (window.matchMedia("(max-width: 992px)").matches && $("#social-sharing").length) {
      $('#footer').css('margin-bottom', socialSharingHeight);
    } else {
      $('#footer').css('margin-bottom', '0');
    }
  }
})(jQuery, window.DGS);