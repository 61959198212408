export var componentsSchemas = {
  'intro-banner': {
    data: [{
      name: 'image',
      element: '.intro-banner-image img',
      type: 'image'
    }, {
      name: 'mobileImage',
      element: '.intro-banner-image',
      type: 'mobileImage'
    }, {
      name: 'video',
      element: '.intro-banner-video source',
      type: 'video'
    }, {
      name: 'embeddedVideo',
      element: '.intro-banner-video-external iframe',
      type: 'iframe'
    }, {
      name: 'content',
      element: '.intro-banner-text',
      type: 'html'
    }, {
      name: 'link',
      element: '.intro-banner-link',
      type: 'html'
    }, {
      name: 'anchorLink',
      element: '.sticky-downlink a',
      type: 'link'
    }, {
      name: 'textList',
      element: '.intro-banner-list-spot-content',
      type: 'list'
    }]
  },
  'rich-text': {
    data: [{
      name: 'content',
      element: '.component-content',
      type: 'html'
    }]
  },
  'text-image-spot': {
    data: [{
      name: 'image',
      element: '.text-image-spot-image',
      type: 'image'
    }, {
      name: 'imageDescription',
      element: '.text-image-spot-image-description',
      type: 'text'
    }, {
      name: 'content',
      element: '.text-image-spot-text',
      type: 'html'
    }, {
      name: 'link',
      element: '.text-image-spot-image a',
      type: 'link'
    }]
  },
  'news-list-spot': {
    data: [{
      name: 'newsListSpot',
      element: 'ul',
      type: 'newsListSpot'
    }]
  },
  'news-spot-item': {
    data: [{
      name: 'title',
      element: '.field-title',
      type: 'text'
    }, {
      name: 'date',
      element: '.field-date',
      type: 'html'
    }, {
      name: 'content',
      element: '.field-content',
      type: 'html'
    }, {
      name: 'image',
      element: '.field-summaryicon img',
      type: 'image'
    }, {
      name: 'bannerImage',
      element: '.field-newsspotimage img',
      type: 'image'
    }]
  },
  'news-navigation': {
    data: [{
      name: 'linkList',
      element: '.component-content ul',
      type: 'linkList'
    }]
  }
};