(function ($) {
  "use strict";

  window.DGS.SeachSpotConfig = {
    initiateAfterBuilder: false
  };
  window.DGS.OnLoad.getInstance().register(function () {
    upgradeAllSearchFieldInputs();
    function upgradeAllSearchFieldInputs() {
      addPlaceholderToSearchFieldInput();
    }
    upgradeSearchFieldsOfContent();
    function upgradeSearchFieldsOfContent() {
      var searchFields = getContentSearchFields();
      searchFields.each(function () {
        upgradeSearchField($(this));
      });
    }
    function addPlaceholderToSearchFieldInput() {
      var language = $('html').attr('lang');
      var dictionary = {
        Search: {
          Placeholder: ""
        }
      };
      DGS.Dictionary.getInstance().getTexts(dictionary, function () {
        getSearchFieldPlaceholderText(dictionary.Search.Placeholder);
      }, language);
    }
    function getContentSearchFields() {
      return $('.content .search-field');
    }
    function upgradeSearchField(searchField) {
      addCloseBtnToSearchField(searchField);
      addSearchBtnToSearchField(searchField);
      manageCloseBtnOfSearchField(searchField);
    }
    function getSearchFieldPlaceholderText(text) {
      var searchFieldInput = $('.search-field input');
      if (searchFieldInput.length > 0) {
        searchFieldInput.each(function () {
          $(this)[0].placeholder = text;
        });
      }
    }
    function addCloseBtnToSearchField(searchField) {
      addIconBtn(searchField, 'close-icon');
    }
    function addSearchBtnToSearchField(searchField) {
      addIconBtn(searchField, 'search-icon');
    }
    function manageCloseBtnOfSearchField(searchField) {
      displayCloseBtnOfSearchField(searchField);
      onFillingSearchFieldInput(searchField);
      clearInputIfCloseBtnClicked(searchField);
    }
    function addIconBtn(btnContainer, iconClass) {
      var btn = btnContainer.children('.' + iconClass);
      if (checkIfBtnExists(btn)) {
        btn.show();
      } else {
        btnContainer.prepend('<div class="' + iconClass + '"></div>');
      }
    }
    function displayCloseBtnOfSearchField(searchField) {
      var input = searchField.find('input')[0];
      var inputIsEmpty = checkIfSearchFieldInputIsEmpty(input);
      var closeBtn = getCloseBtnOfSearchField(searchField);
      if (inputIsEmpty) {
        closeBtn.hide();
      } else {
        closeBtn.show();
      }
    }
    function onFillingSearchFieldInput(searchField) {
      var input = searchField.find('input')[0];
      input.addEventListener('input', function () {
        displayCloseBtnOfSearchField(searchField);
      });
    }
    function clearInputIfCloseBtnClicked(searchField) {
      var closeBtn = getCloseBtnOfSearchField(searchField);
      var input = searchField.find('input')[0];
      closeBtn.on('click', function () {
        input.value = '';
        closeBtn.hide();
      });
    }
    function checkIfBtnExists(btn) {
      var btnExists = btn.length > 0;
      return btnExists;
    }
    function checkIfSearchFieldInputIsEmpty(input) {
      return input.value === "";
    }
    function getCloseBtnOfSearchField(searchField) {
      var closeBtn = searchField.find('.close-icon');
      return closeBtn;
    }

    /*Corporate templates search.js - modified*/

    var MOBILE_BP = window.matchMedia("(max-width: 767px)");
    var assetPath = '/webservices/search.svc/',
      searchResult = 'search/',
      searchString = '',
      lazyLoad = true,
      loadPage = 1,
      currentSearch = window.location.href,
      language = $('html').attr('lang');
    if ($('.search-result-spot').length) {
      if (!$('.search-result-spot').data('no-results')) {
        $('.no-search-result').remove();
      } else {
        $('.search-result').css('margin-top', '4em');
      }
    }
    var initSearchFieldSpot = function initSearchFieldSpot() {
      $('.search-field-spot').each(function () {
        var $this = $(this),
          searchResult = $this.attr('data-result-page');
        $this.on('click', function (e) {
          e.preventDefault();
          var target = $(e.target);
          searchString = $("input", this).val();
          if (target.is('.search-icon') && searchString != '') {
            currentSearch = searchResult + '?q=' + removeSpecialChars(searchString);
            window.location = currentSearch;
          }
        });
        $('input', $this).autocomplete({
          source: function source(request, response) {
            $.ajax({
              url: assetPath + 'suggest/' + $('input', $this).val() + '/' + language,
              type: 'GET',
              dataType: 'json',
              success: function success(data) {
                response(data.Suggests);
              },
              error: function error(xhr, textStatus, errorThrown) {
                console.log('error search-field-spot ', url);
              }
            });
          },
          select: function select(a, b) {
            searchString = '?q=' + removeSpecialChars(b.item.value);
            window.location = searchResult + searchString;
          },
          open: function open() {
            $("ul.ui-menu").width($(this).innerWidth());
            if (MOBILE_BP.matches) {
              $("#ui-id-1").css('left', '0');
            }
          }
        }).autocomplete("widget").addClass("ui-autocomplete-search");
        $this.keypress(function (e) {
          searchString = $("input", this).val();
          if (e.keyCode === 13 && searchString !== '') {
            e.preventDefault();
            searchString = '?q=' + removeSpecialChars(searchString);
            window.location = searchResult + searchString;
          }
        });
      });
    };
    if (window.DGS.SeachSpotConfig.initiateAfterBuilder) {
      window.DGS.Event.getInstance().on(window.DGS.Events.BUILDER_DONE, function () {
        initSearchFieldSpot();
      });
    } else {
      initSearchFieldSpot();
    }
    $('.search-result-spot').each(function () {
      var $this = $(this),
        $span = $('.search-result-count span'),
        searchString = $('input', $this).val();
      $('input', $this).autocomplete({
        source: function source(request, response) {
          var url = assetPath + 'suggest/' + $('input', $this).val() + '/' + language;
          $.ajax({
            url: url,
            type: 'GET',
            dataType: 'json',
            success: function success(data) {
              response(data.Suggests);
            },
            error: function error(xhr, textStatus, errorThrown) {
              console.log('error search-result-spot ', url);
            }
          });
        },
        select: function select(a, b) {
          searchString = '?q=' + removeSpecialChars(b.item.value);
          currentSearch = searchString;
          window.location = currentSearch;
        },
        open: function open() {
          $("ul.ui-menu").width($(this).innerWidth());
        }
      }).autocomplete("widget").addClass("ui-autocomplete-search");
      $this.keypress(function (e) {
        if (e.keyCode === 13 && $('input', $this).val() !== '') {
          e.preventDefault();
          searchString = '?q=' + removeSpecialChars($('input', $this).val());
          window.location = searchString;
        }
      });
      $this.on('click', function (e) {
        e.stopPropagation();
        var $target = $(e.target);
        if (e.offsetX > $span.width() && e.offsetX < $span.width() + 40 && $target.is('span')) {
          searchString = '?q=' + removeSpecialChars($('input', $this).val());
          window.location = searchString;
        } else {
          $('input', $this).focus();
        }
      });
      $('.search-result-count, .search-result-spot .search-icon').click(function (e) {
        var inputVal = $(this).parent().children('input').val();
        if (inputVal !== "") {
          searchString = '?q=' + removeSpecialChars(inputVal);
          window.location = searchString;
        }
      });
    });
    function searchScroll(windowScroll) {
      var $bc = $('#wrapper .component.breadcrumb'),
        $footer = $('#wrapper #footer'),
        offset = $bc.length ? $bc.offset() : $footer.length ? $footer.offset() : null,
        wh = $(window).height(),
        liCount = $('.search-result ul li').length;
      if (offset !== null && offset.top - wh < windowScroll && lazyLoad && liCount >= 10) {
        lazyLoad = false;
        loadPage++;
        currentSearch = assetPath + searchResult + searchString + '/' + loadPage;
        nextPage();
      }
      ;
    }
    ;
    function nextPage() {
      var loadMore = true,
        $searchResult = $('.search-result-spot .search-result'),
        liTemplate = $('#search-element-template').html(),
        loadMoreTemplate = $('#loading-more-template').html();
      $searchResult.append(loadMoreTemplate);
      $.ajax({
        url: currentSearch,
        type: 'GET',
        dataType: 'json',
        success: function success(data, textStatus, xhr) {
          var newHtml = '';
          $.each(data.Elements, function (index, liData) {
            var theData = liTemplate.format(liData.Url, liData.Title, liData.NiceUrl, liData.Summary, liData.Number);
            newHtml += theData;
            if (liData.Number >= data.Count) loadMore = false;
          });
          $('.loading-more').remove();
          $('ul', $searchResult).append(newHtml);
          if (loadMore) lazyLoad = true;
        },
        error: function error(xhr, textStatus, errorThrown) {
          console.log('error nextPage ', url);
        }
      });
    }
    window.DGS.Scroller.getInstance().register(searchScroll);
    function removeSpecialChars(query) {
      if (query != "") {
        var restrictedChars = $('.search-field-spot').attr('data-restricted-chars');
        if (restrictedChars.length > 0) {
          var restrictedCharsList = restrictedChars.split("");
          for (var i = 0; i < restrictedCharsList.length; i++) {
            query = query.split(restrictedCharsList[i]).join(" ");
          }
        }
      }
      return query;
    }

    /*End of: Corporate templates search.js - modified*/
  });
})(jQuery);