import { resolveImageSrc } from "@demant/wdh";
require('../../../../wdh-feature/wdh-blog/src/js/wdh-blog');
require('../../../../wdh-feature/wdh-blog/src/js/wdh-categories');
(function ($, DGS) {
  DGS.OnLoad.getInstance().register(function () {
    var language = $('html').attr('lang');
    var MOBILE_BP = window.matchMedia("(max-width: 767px)");
    var TABLET_AND_UP_BP = window.matchMedia("(min-width: 767px)");
    var DESKTOP = window.matchMedia("(min-width: 991px)");
    var isIE = navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1;
    manageBlog();
    function manageBlog() {
      manageCategories();
      manageSinglePostPage();
      manageAuthorPage();
    }
    function manageCategories() {
      if (MOBILE_BP.matches) {
        blogCategories();
      }
    }
    function manageSinglePostPage() {
      singlePostBanner();
      blogAuthor();
      getRelatedBlogsHeaderText();
      relatedPostToLinkPostPage();
    }
    function manageAuthorPage() {
      authorBanner();
      slideRelatedBlogs();
      relatedPostToLinkAuthorPage();
    }
    function blogCategories() {
      buildMobileCategoriesContainer();
      moveCustomCategoriesToMobileContainer();
      moveCurrentActiveCategoryToTop();
      highLightAllCategory();
      buildSwitchBtn();
    }
    function singlePostBanner() {
      if ($('.component.blog').length > 0) {
        var page = "single-post-page";
        var blogTitle = $('.blog-title')[0];
        var blogDate = $('.blog-date');
        var blogImage = resolveImageSrc($('.blog-image img')[0]);
        createCustomBlogBanner(page, blogDate, blogTitle, blogImage);
      }
    }
    function blogAuthor() {
      var authorName = $('.blog-author-name');
      var authorTitle = $('.blog-author-title');
      authorName.wrap('<div class="blog-author-header"></div>');
      authorTitle.insertBefore(authorName);
    }
    function getRelatedBlogsHeaderText() {
      var dictionary = {
        Blogs: {
          RelatedBlogsTitle: ""
        }
      };
      DGS.Dictionary.getInstance().getTexts(dictionary, function () {
        setRelatedBlogsHeaderText(dictionary.Blogs.RelatedBlogsTitle);
      }, language);
    }
    function relatedPostToLinkPostPage() {
      var blogsContainer = $('.component.related-blogs');
      var post = $('.component-content ul > li > div');
      var link = $('.news-link .link');
      relatedPostToLink(blogsContainer, post, link);
    }
    function authorBanner() {
      if ($('.component.author-details').length > 0) {
        var page = "author-page";
        var authorName = $('.author-intro h2');
        var authorTitle = $('.author-details .author-title').clone();
        var authorImage = $('.author-details .author-image img')[0].src;
        createCustomBlogBanner(page, authorName, authorTitle, authorImage);
      }
    }
    function slideRelatedBlogs() {
      $('.author-related-blogs > .component-content > ul').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        responsive: [{
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: false
          }
        }, {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: false,
            arrows: false
          }
        }]
      });
      manageDotsHighlight();
      DGS.Resizer.getInstance().register(function () {
        manageDotsHighlight();
      });
    }
    function relatedPostToLinkAuthorPage() {
      var blogsContainer = $('.component.author-related-blogs');
      var post = $('.component-content .slick-track > li > div');
      var link = $('.author-blog-link .link');
      relatedPostToLink(blogsContainer, post, link);
    }
    function buildMobileCategoriesContainer() {
      var categoriesContainer = "<div class='blog-categories-mobile-container hide-elements'></div>";
      $('.blog-categories-content-wrapper').append(categoriesContainer);
    }
    function moveCustomCategoriesToMobileContainer() {
      var blogCategories = $('.blog-category');
      blogCategories.each(function (index) {
        if (index > 0) {
          $(this).appendTo($('.blog-categories-mobile-container'));
        }
      });
    }
    function moveCurrentActiveCategoryToTop() {
      var mobileCategoriesContainer = $('.blog-categories-mobile-container');
      var activeCategory = $('.blog-categories-mobile-container .blog-category a.active');
      activeCategory.parent().prependTo(mobileCategoriesContainer);
    }
    function highLightAllCategory() {
      var allCategory = $('.blog-categories-content-wrapper .blog-category').first();
      allCategory.addClass('all');
    }
    function buildSwitchBtn() {
      var activeCategory = $('.blog-categories-mobile-container .blog-category a.active');
      if (activeCategory.length > 0) {
        activeCategory.parent().addClass('switch-btn');
        $('.blog-categories-mobile-container .blog-category').first().append("<span class='arrow-down'></span>");
      } else {
        $('.blog-categories-mobile-container .blog-category').first().addClass('switch-btn');
        $('.blog-categories-mobile-container .blog-category').first().append("<span class='arrow-down'></span>");
      }
      addBehaviorToSwitchBtn();
      disableCurrentLinkIfBlogList();
    }
    function createCustomBlogBanner(page, text1, text2, imgSrc) {
      var customBanner = $("<div class='component custom-blog-banner " + page + " component-ready'><div class='component-content'><div class='blog-text-container'></div></div></div>");
      customBanner.insertBefore($('.blog-categories')).css('backgroundImage', 'url(' + imgSrc + ')');
      var blogInnerBannerText = $('.custom-blog-banner .blog-text-container');
      if (!MOBILE_BP.matches) {
        blogInnerBannerText.append(text1).append(text2);
      }
    }
    function setRelatedBlogsHeaderText(text) {
      var header = $('.related-blogs .related-title');
      header.text(text);
      if (!checkIfRelatedBlogsExist()) {
        header.hide();
      }
    }
    function manageDotsHighlight() {
      highlightActiveDots();
      highlightDotsOnWindowResize();
      highlightDotsonSwipe();
      highlightDotsOnDotClick();
      highlightDotsOnArrowClick();
    }
    function relatedPostToLink(postsContainer, posts, link) {
      postsContainer.each(function () {
        var $this = $(this);
        var $relatedPosts = $(posts, $this);
        $relatedPosts.each(function (i, relatedPost) {
          var $link = $(link, $(relatedPost));
          $(relatedPost).click(function (e) {
            if (!$(e.target).hasClass('link')) {
              $link[i].click();
            }
          });
        });
      });
    }
    function checkIfRelatedBlogsExist() {
      var blogPost = $('.related-blogs ul li');
      var blogPostExists = blogPost.length > 0;
      return blogPostExists;
    }
    function addBehaviorToSwitchBtn() {
      var switchBtn = $('.blog-categories-mobile-container .blog-category.switch-btn .arrow-down');
      var customCategoriesOpen = false;
      switchBtn.on("click", function () {
        if (!customCategoriesOpen) {
          $('.blog-categories-mobile-container').removeClass('hide-elements');
          customCategoriesOpen = true;
        } else {
          $('.blog-categories-mobile-container').addClass('hide-elements');
          customCategoriesOpen = false;
        }
      });
    }
    function disableCurrentLinkIfBlogList() {
      var checkIfBlogListIsOn = $('.component.blog-list').length > 0;
      var categoryLink = $('.blog-category a.active');
      if (checkIfBlogListIsOn) {
        categoryLink.on("click", function (e) {
          e.preventDefault();
        });
      }
    }
    DGS.Resizer.getInstance().register(function () {
      manageFlexImgsForIE();
    });
    function manageFlexImgsForIE() {
      var postPageImg = $('.related-blogs .news-image img');
      var blogListImg = $('.blog-list .news-image img');
      var authorPageImg = $('.author-related-blogs .author-blog-image img');
      if (isIE) {
        setHeightForImageContainer(postPageImg);
        setHeightForImageContainer(blogListImg);
        setHeightForImageContainer(authorPageImg);
      }
    }
    function setHeightForImageContainer(container) {
      container.each(function () {
        var originalImgWidth = $(this)[0].naturalWidth;
        var originalImgHeight = $(this)[0].naturalHeight;
        var ratio = (originalImgHeight / originalImgWidth).toFixed(2);
        var styledImgWidth = $(this)[0].width;
        var imgContainerHeight = (styledImgWidth * ratio).toFixed(2);
        $(this).parent().css('height', imgContainerHeight);
      });
    }
    function highlightDotsOnWindowResize() {
      highlightActiveDotsOnEvent($(window), 'resize');
    }
    function highlightDotsonSwipe() {
      var posts = $('.author-related-blogs > .component-content > ul');
      highlightActiveDotsOnEvent(posts, 'swipe');
    }
    function highlightDotsOnDotClick() {
      var dots = $('.slick-slider .slick-dots li');
      highlightActiveDotsOnEvent(dots, 'click');
    }
    function highlightDotsOnArrowClick() {
      var arrows = $('.slick-slider .slick-arrow');
      highlightActiveDotsOnEvent(arrows, 'click');
    }
    function highlightActiveDotsOnEvent(buttons, event) {
      buttons.each(function () {
        $(this).on(event, function () {
          highlightActiveDots();
        });
      });
    }
    function highlightActiveDots() {
      if (TABLET_AND_UP_BP.matches) {
        var activeDot = $('.slick-dots .slick-active');
        var allDots = $('.slick-dots li');
        allDots.removeClass('active-dot');
        allDots.each(function () {
          if (activeDot.is(allDots.last())) {
            allDots.first().addClass('active-dot');
          } else {
            activeDot.next().addClass('active-dot');
          }
          if (DESKTOP.matches) {
            if (activeDot.is(allDots.last())) {
              allDots.first().next().addClass('active-dot');
            } else if (activeDot.is(allDots.last().prev())) {
              allDots.first().addClass('active-dot');
              allDots.last().addClass('active-dot');
            } else {
              activeDot.next().next().addClass('active-dot');
            }
          }
        });
      }
    }
  });
})(jQuery, window.DGS);