require('../../../corporate-template/js/feature/intro-banner.js');
DGS.Resizer.getInstance().register(function () {
  $('.component.intro-banner').each(function () {
    var self = $(this);
    var MQ = window.matchMedia("(max-width: 768px)");
    if (MQ.matches) {
      self.addClass('content-overflow');
    }
  });
  adjustMapTextForLocationSelector();
  function adjustMapTextForLocationSelector() {
    var textContainer = $('.component.intro-banner.adjust-text-to-map');
    textContainer.removeClass('content-overflow');
  }
});