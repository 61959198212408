require('core-js');require('regenerator-runtime/runtime');import $ from 'jquery';
window.jQuery = $;
window.$ = $;
// webpack

// Foundation
require('../../../wdh-foundation/dgs/dgs-api.js');
require('../../../wdh-foundation/dgs/dgs-events.js');
require('@demant/megamenu');
require('../../../wdh-foundation/dgs-sharing/dgs-sharing.js');
require('../../../wdh-foundation/dgs-utils/dgs-utils.js');
require('@demant/burgermenu');
require('masonry-layout');
require('jquery-nice-select/js/jquery.nice-select.min.js');
require('jquery-ui-dist/jquery-ui.min.js');
require('lightgallery/src/js/lightgallery.js');
require('iframe-resizer');
require('@demant/legacy/custom-scroll');

// Feature
import { TweenMax } from "gsap";
require('./../../../wdh-feature/wdh-feature-b2b/src/js/b2b-shop');
require('./feature/blog.js');
require('./feature/breadcrumbs.js');
require('./feature/center-locator.js');
require('./feature/center-list-spot.js');
require('./feature/center-spot.js');
require('./feature/color-selector-spot.js');
require('./feature/cookies.js');
require('./feature/data-hierarchy-spot.js');
require('./feature/download-center');
require('./feature/video-spot.js');
require('./feature/form.js');
require('./feature/geo-ip-prompt.js');
require('./feature/iframe.js');
require('./feature/image-spot.js');
require('./feature/intro-banner.js');
require('./feature/language-selector-spot.js');
require('./feature/location-spot/location-spot.js');
require('./feature/rich-text.js');
require('./feature/search.js');
require('./feature/social-sharing');
require('./feature/text-image-spot.js');
require('./feature/component-data-list-spot.js');

// Project
require('./project/main.js');
require('./project/header.js');
require('./project/footer.js');
require('./project/location-selector.js');
require('./project/custom-a-login-spot');

// React based components
require('../flex-layout/components/news-list-spot/js/component-news-list-spot');
require('../flex-layout/components/news-spot/js/component-news-spot');
require('../flex-layout/components/news-navigation/js/component-news-navigation');
require('@demant/wdh-gdpr/lib/default.implementation.js');