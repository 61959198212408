require('../../../corporate-template/js/project/main.js');
(function ($, DGS) {
  DGS.OnLoad.getInstance().register(function () {
    function makePhoneNumberClickable() {
      $('.clickable-phone-number').each(function () {
        var self = $(this);
        var phoneNumber = self[0].innerText;
        self.wrap("<a href='tel:".concat(phoneNumber, "'></a>"));
      });
    }
    makePhoneNumberClickable();
    function manageFocusOnTabKeyUse(e) {
      if (e.keyCode === 9) {
        $('body').addClass('user-is-tabbing');
        $(window).off('keydown', manageFocusOnTabKeyUse);
        $(window).on('mousedown', manageFocusOnMouseUse);
      }
    }
    function manageFocusOnMouseUse() {
      $('body').removeClass('user-is-tabbing');
      $(window).off('mousedown', manageFocusOnMouseUse);
      $(window).on('keydown', manageFocusOnTabKeyUse);
    }
    function manageFocus() {
      $(window).on('keydown', manageFocusOnTabKeyUse);
    }
    manageFocus();
    function manageFocusOnLinksWithOrangeButton() {
      var links = $('a > span.like-orange-button');
      var isIE = navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1;
      if (isIE) {
        links.each(function () {
          $(this).parent().addClass('focus-orange-btn');
        });
      }
    }
    manageFocusOnLinksWithOrangeButton();
    function correctLinksWithButtons() {
      var linksContainingButtons = $('a > span[class*="button"]');
      linksContainingButtons.parent().addClass("contains-btn");
    }
    correctLinksWithButtons();
  });
})(jQuery, window.DGS);