(function ($, DGS) {
  DGS.OnLoad.getInstance().register(function () {
    function disableLinksToEmptyPages() {
      var language = document.documentElement.lang;
      var emptyPages = ['/hearing-loss', '/hearing-aids', '/hearing-aids/hearing-aids', '/support', '/support/connectivity', '/professionals', '/press'];
      var breadcrumbsLinks = $('.breadcrumb.component ol li a');
      breadcrumbsLinks.each(function (index, el) {
        var path = el.pathname;
        if (language !== 'en') {
          path = "/".concat(el.pathname.split('/').slice(2).join('/'));
        }
        var isPageEmpty = emptyPages.includes(path);
        if (isPageEmpty) {
          $(el).addClass('disabled-link').attr('tabIndex', '-1');
          $(el).click(function (e) {
            e.preventDefault();
          });
        }
      });
    }
    disableLinksToEmptyPages();
    function disableLastLink() {
      var lastLink = $('.breadcrumb.component ol li.last a');
      lastLink.attr('tabIndex', '-1');
      lastLink.click(function (e) {
        e.preventDefault();
      });
    }
    disableLastLink();
  });
})(jQuery, window.DGS);