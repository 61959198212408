import { resolveImageSrc } from "@demant/wdh";
require('../../../corporate-template/js/feature/text-image-spot.js');
(function ($, DGS) {
  DGS.OnLoad.getInstance().register(function () {
    var DESKTOP_BP = window.matchMedia("(min-width: 992px)");
    updateTextImgSpot();
    function updateTextImgSpot() {
      imgAsBackgroundForImgWidth50();
      imgAsBackgroundForImgWidth35();
    }
    function imgAsBackgroundForImgWidth35() {
      var textImageSpot = $('.text-image-spot.img-35-text-65');
      textImageSpot.each(function () {
        var self = $(this);
        imgAsBackground(self, 35, 'vw');
      });
    }
    function imgAsBackgroundForImgWidth50() {
      var textImageSpot = $('.text-image-spot').not('.img-35-text-65, .fit-content-into-page-container, .img-not-as-bg');
      textImageSpot.each(function () {
        var self = $(this);
        imgAsBackground(self, 50, 'vw');
      });
    }
    function imgAsBackground(textImgSpot, imgContainerWidth, widthUnits) {
      var img = textImgSpot.find('img')[0];
      var imgUrl = resolveImageSrc(img);
      var ratio = (img.height / img.width).toFixed(2);
      var imgContainer = textImgSpot.find('.text-image-spot-image-container');
      var imgContainerHeight = imgContainerWidth * ratio + widthUnits;
      var imgContainerStyle = {
        'background-image': 'url("' + imgUrl + '")',
        'height': imgContainerHeight
      };
      addStyleIfDesktop(imgContainer, imgContainerStyle);
      DGS.Resizer.getInstance().register(function () {
        addStyleIfDesktop(imgContainer, imgContainerStyle);
      });
    }
    function addStyleIfDesktop(element, style) {
      if (DESKTOP_BP.matches) {
        element.css(style);
      } else {
        element.removeAttr('style');
      }
    }
  });
})(jQuery, window.DGS);