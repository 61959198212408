(function ($, DGS) {
  DGS.OnLoad.getInstance().register(function () {
    var selectSiteHeader = $('#footer .section-one .section-three h3');
    var selectSiteList = $('#footer .section-one .section-three ul');
    var clicked = false;
    selectSiteHeader.on('click', function (e) {
      e.preventDefault();
      if (!clicked) {
        showSelectSiteList();
        clicked = true;
      } else {
        hideSelectSiteList();
        clicked = false;
      }
    });
    $('#footer .section-one .section-three h3, #footer .section-one .section-three ul').hover(function () {
      if (!clicked) {
        showSelectSiteList();
      }
    }, function () {
      if (!clicked) {
        hideSelectSiteList();
      }
    });
    function showSelectSiteList() {
      selectSiteList.addClass('display-list');
    }
    function hideSelectSiteList() {
      selectSiteList.removeClass('display-list');
    }
    removeBorderFromFirstLinkInLine();
    DGS.Resizer.getInstance().register(function () {
      removeBorderFromFirstLinkInLine();
    });
    function removeBorderFromFirstLinkInLine() {
      var links = getFooterLinks();
      var maxWidth = getFooterLinkListWidth();
      var currentLineWidth = 0;
      links.each(function () {
        var currentLink = $(this);
        var currentLinkWidth = currentLink.outerWidth();
        currentLineWidth += currentLinkWidth;
        currentLink.removeClass('first-in-line');
        if (currentLineWidth > maxWidth) {
          currentLink.addClass('first-in-line');
          currentLineWidth = currentLinkWidth;
        }
      });
    }
    function getFooterLinks() {
      var links = $('#footer .section-two .rich-text ul li');
      return links;
    }
    function getFooterLinkListWidth() {
      var linklistwidth = $('#footer .section-two .rich-text ul').width();
      return linklistwidth;
    }
  });
})(jQuery, window.DGS);