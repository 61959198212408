require('../../../corporate-template/js/feature/image-spot.js');
(function ($, DGS) {
  DGS.OnLoad.getInstance().register(function () {
    updateImgSpot();
    function updateImgSpot() {
      $('.image-spot').each(function () {
        var self = $(this);
        var amountOfImgSpotElements = getAmountOfImgs(self);
        addClassWithImgsAmount(self, amountOfImgSpotElements);
      });
    }
    function getAmountOfImgs(el) {
      var amountOfImgs = el.find('.image-spot-element').length;
      return amountOfImgs;
    }
    function addClassWithImgsAmount(el, amountOfImgsInEl) {
      if (amountOfImgsInEl >= 5) {
        el.addClass('image-spot-elements-' + 5);
      } else {
        el.addClass('image-spot-elements-' + amountOfImgsInEl);
      }
    }
  });
})(jQuery, window.DGS);